import sum from 'lodash.sum'

export default [
  {
    displayName: 'All teams',
    type: 'ALL',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return sum(pools.map(p => p.teams.length))
    },
    reg: true,
    pool: false,
    bracket: true,
    needSeed: false,
    standings: true
  },
  {
    displayName: 'Seed range',
    type: 'RANGE',
    min: 1,
    max: 8,
    teamCount: function (pools) {
      return this.max
    },
    reg: true,
    pool: false,
    bracket: true,
    needSeed: true,
    standings: true
  },
  {
    displayName: 'Select seeds',
    type: 'SELECT',
    min: 1,
    max: 8,
    teamCount: function (pools) {
      return this.max
    },
    reg: true,
    pool: false,
    bracket: true,
    needSeed: true,
    standings: true
  },
  {
    displayName: 'Top N',
    description: 'N (Total number of playoff teams)',
    type: 'TOP_N_EACH',
    min: 1,
    max: 3,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    pool: true,
    bracket: false,
    needSeed: true,
    standings: false
  },
  {
    displayName: 'Advanced',
    description: 'poolNumber, poolFinish | poolNumber, poolFinish',
    type: 'MAPPED',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    pool: true,
    bracket: false,
    needSeed: true,
    advanced: true,
    standings: false
  },
  {
    displayName: 'Top N Advanced',
    description: 'poolNumber, poolFinish | poolNumber, poolFinish',
    type: 'TAKE',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    pool: true,
    bracket: false,
    needSeed: true,
    advanced: true,
    standings: false
  },
  {
    displayName: 'NOT Top N',
    description: 'Teams not in the top N of pool',
    type: 'NOT_TOP_N_EACH',
    min: 1,
    max: 3,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    pool: true,
    bracket: false,
    needSeed: true,
    standings: false
  },
  {
    displayName: 'NOT Top N Advanced',
    description: 'poolNumber, poolFinish | poolNumber, poolFinish',
    type: 'NOT_TAKE',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    pool: true,
    bracket: false,
    needSeed: true,
    advanced: true,
    standings: false
  },
  {
    displayName: 'Specific Pool Finishers',
    description: 'Use this to break about specific team into their own pool or bracket',
    type: 'POOL_FINISH',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    reg: false,
    pool: true,
    bracket: true,
    needSeed: false,
    standings: false
  },
  {
    displayName: 'Specific Teams',
    description: 'Use this to break about specific team into their own pool or bracket',
    type: 'SPECIFIC',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    reg: false,
    pool: true,
    bracket: true,
    needSeed: false,
    standings: false
  },
  {
    displayName: 'Match Losers',
    description: 'Use this to break out team that lose a specific bracket match',
    type: 'MATCH_LOSERS',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    reg: true,
    pool: false,
    bracket: true,
    needSeed: false,
    standings: false
  },
  {
    displayName: 'Match Winners',
    description: 'Use this to break out team that win a specific bracket match',
    type: 'MATCH_WINNERS',
    min: 1,
    max: 9999,
    teamCount: function (pools) {
      return this.max * pools.length
    },
    reg: true,
    pool: false,
    bracket: true,
    needSeed: false,
    standings: false
  }
  // {
  //   displayName: 'Match Winners',
  //   description: 'Use this to break about team that win a specific bracket match',
  //   type: 'MATCH_WINNERS',
  //   min: 1,
  //   max: 9999,
  //   teamCount: function (pools) {
  //     return this.max * pools.length
  //   },
  //   reg: true,
  //   pool: false,
  //   bracket: true,
  //   needSeed: false
  // }
]
